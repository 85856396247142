import * as React from "react";
import { graphql } from "gatsby";
import Layout from "../components/layout";
import Banner from "../components/home/banner";
import Service from "../components/home/service";
//import Portfolio from "../components/home/portfolio";
import Seo from "../components/seo";

const Home = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata?.title || `Title`;
  return (
    <Layout location={location} title={siteTitle}>
      <Seo title="Home" />
      <Banner />
      <Service />
      {/*<Portfolio />*/}
    </Layout>
  );
};

export default Home;

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
        apiUrl
      }
    }
  }
`;
