import React from "react";

const service = (props) => {
  return (
    <section className="page-section" id="services">
      <div className="container">
        <div className="text-center">
          <h2 className="section-heading text-uppercase">Services</h2>
          <h3 className="section-subheading text-muted">About my services</h3>
        </div>
        <div className="row text-center">
          <div className="col-md-4">
            <span className="fa-stack fa-4x">
              <i className="fas fa-circle fa-stack-2x text-primary" />
              <i className="fas fa-laptop fa-stack-1x fa-inverse" />
            </span>
            <h4 className="my-3">Web Development</h4>
            <p className="text-muted">
              I Create Web Application in PHP, Python, React, Angular, Laravel,
              Node.js, Gatsby, Typescript, Javascript
            </p>
          </div>
          <div className="col-md-4">
            <span className="fa-stack fa-4x">
              <i className="fas fa-circle fa-stack-2x text-primary" />
              <i className="fas fa-mobile fa-stack-1x fa-inverse" />
            </span>
            <h4 className="my-3">Mobile App Development</h4>
            <p className="text-muted">
              I create Hybrid Mobile Apps on both the android and ios platform
              in React Native and Flutter
            </p>
          </div>
          <div className="col-md-4">
            <span className="fa-stack fa-4x">
              <i className="fas fa-circle fa-stack-2x text-primary" />
              <i className="fas fa-cloud fa-stack-1x fa-inverse" />
            </span>
            <h4 className="my-3">
              Backend Rest API development and AWS Cloud deployment
            </h4>
            <p className="text-muted">
              I create backend API in Python, Node.js, PHP, MongoDB, and
              deployment on AWS Cloud Services
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default service;
