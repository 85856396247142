import React from "react";
//import { StaticImage } from "gatsby-plugin-image";
import background from "../../images/header-bg.jpg";
const banner = (props) => {
  return (
    <header
      style={{
        backgroundImage: `url(${background})`,
        boxShadow: "inset 0 0 0 2000px rgba(98, 81, 91, 0.3)",
      }}
      className="masthead"
    >
      <div className="container">
        <div className="masthead-subheading">
          Welcome To Crew Code Solution!
        </div>
        <div style={{ fontSize: 30 }} className="masthead-heading w-75 mx-auto">
          Your Developer for Web and Mobile App, Backend API, AWS Cloud
        </div>
        <a class="btn btn-primary btn-xl" href="#services">
          Know More About Services
        </a>
      </div>
    </header>
  );
};

export default banner;
